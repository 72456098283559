import React from "react"
import Layout from "../components/layout"
import "../styles/details.scss";
import Img from 'gatsby-image';
import soldImage from '../../static/sold_tag.png';
import {PrimaryLink} from "../components/contact-form";

const NonStretchedImage = props => {
  console.log(props.fluid.presentationWidth)
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }

  return <Img {...normalizedProps} />
}


export default (props) => {
  let data = props.data
  let workData = props.pathContext.work
  console.log(props.pathContext)
  return (
    <Layout>
      <div className="detail-container">
        <section className="art-section">
          <div style={{width: "600", height:"500"}}>  
          <NonStretchedImage fluid={data.imageSharp.fluid} />
          </div>
        </section>
        <section className="details-section">
          <p><h1>{workData.title}</h1>
            {workData.sold && <img className="sold-tag" alt="sold" src={soldImage} />}
          </p>

          <p>{workData.size} ({workData.frame})</p>
          <p><PrimaryLink title={workData.sold ? "Commission similiar art" : "Enquire"} onClick={(e) => {
              window.location = "/?ref=" + workData.title + "#contact"
          }}/></p>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ($slug: String!) {
    imageSharp(fields: {slug: {eq: $slug}}) {
      fluid(maxHeight: 600, quality: 100, background: "white", fit: COVER) {
        ...GatsbyImageSharpFluid_tracedSVG
        presentationWidth
      }
      
    }
  }
`
